<template>
  <static-fullscreen-card>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <template v-slot:title> ({{ data.id }}) Закрытие объекта "{{ data.object && data.object.name }}" [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <a-btn-status v-if="getAccess('objectClose.status', { users: [data.createdby_id] })" :items="statusItems" @click="changeStatus($event)" :disabled="canStatus" />
      <a-btn-edit v-if="accessEdit && data.status <= 1" @click="showEditDialog = true" :disabled1="!canEdit" />
      <a-btn-delete
        v-if="id && getAccess('objectClose.delete', { users: [data.createdby_id] }) && (data.status || 0) === 0"
        :disabled="!canDelete"
        @click="removeDialogShow = true"
      />
    </template>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: !true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <v-simple-table dense>
            <tbody>
              <tr v-for="(el, i) in actions" :key="i">
                <td class="text-left" :style="`font-weight: 500; height: 38px`">{{ el.title }}:</td>
                <td class="text-left" :style="`font-weight: 500; height: 38px`">
                  <v-btn small @click="doAction(el.name)"> обновить</v-btn>
                </td>
                <td class="text-left px-0" :style="`height: 38px`">
                  {{ actionStatus[el.name] === 0 ? "Не готово" : actionStatus[el.name] === -1 ? "Ошибка" : actionStatus[el.name] === 1 ? "Готово" : "Error" }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2 pt-1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="t.name == 'orderGoods'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableGoods"
                  :dataTable="data.data_goods"
                  :useQuery="false"
                  :model="modelGoods"
                  :searchable="false"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                  }"
                  @click="onClickRow($event)"
                >
                  <template v-slot:top> </template>
                </a-table-f-data2>
              </v-card-text>
              <v-card-text v-if="t.name == 'orderService'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableService"
                  :dataTable="data.data_service"
                  :useQuery="false"
                  :model="modelService"
                  :searchable="false"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                  }"
                  @click="onClickRow($event)"
                >
                  <template v-slot:top> </template>
                </a-table-f-data2>
              </v-card-text>
              <v-card-text v-if="t.name == 'store'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableStore"
                  :dataTable="data.data_store"
                  :useQuery="false"
                  :model="modelStore"
                  :searchable="false"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                  }"
                  @click="onClickRow($event)"
                >
                  <template v-slot:top> </template>
                </a-table-f-data2>
              </v-card-text>
              <TabBalance v-if="t.name == 'balance'" :data="{ data, id: data.object_id }" :height="detailHeight" />
              <v-card-text v-if="t.name == 'account'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <s-document-acc-record :id="id" :name="m.accDocName" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="id" />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <a-loader v-if="loading" />
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel, statusDoc, validate } from "@/components/mixings";
export default {
  mixins: [saveEl, autoHeightBlock, getAccess, popupMenu, removeEl, genModel, statusDoc, validate],
  props: {
    idShow: Number,
  },
  components: {
    TabBalance: () => import("./tabs/tabObjectBalance"),
  },
  data() {
    return {
      needUpdateComments: 0,
      id: 0,
      idEdit: 0,
      idDeleting: 0,
      fieldsRO: [],
      fab: false,
      suEditShow: false,
      showEditDialog: false,
      showAppendixEditDialog: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      loaded: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.objectClose,

      tab: "",
      tabs: [
        { name: "orderGoods", title: "Заказы товаров", show: true },
        { name: "orderService", title: "Заказы услуг", show: true },
        { name: "store", title: "Товары на складе", show: true },
        { name: "balance", title: "Баланс", show: true },
        { name: "account", title: "Проводки", show: true },
      ],
      balance: {},
      actionStatus: {},
    };
  },
  created() {
    this.loading = true;
    this.$root.title = this.m.title;
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    modelService() {
      const model = this.getModelList(this.m, "listService");
      return model;
    },
    modelGoods() {
      const model = this.getModelList(this.m, "listGoods");
      return model;
    },
    modelStore() {
      const model = this.getModelList(this.m, "listStore");
      return model;
    },
    actions() {
      let res = [
        { name: "orderGoods", title: "Заказы товаров" },
        { name: "orderService", title: "Заказы услуг" },
        { name: "store", title: "Товары на складе" },
      ];
      return res;
    },
    statuses() {
      return this.m.statuses;
    },

    api() {
      return this.m.api;
    },
    url() {
      return this.m.api;
    },

    accessEdit() {
      return this.getAccess("objectClose.edit", { users: [this.data.createdby_id] });
    },

    canEdit() {
      return true;
    },
    canDelete() {
      return true;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      model = [
        ...model,
        { name: "debit", title: "Приход", type: "number", value: this.balance?.debit },
        { name: "credit", title: "Расход", type: "number", value: this.balance?.credit },
      ];
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    configHead() {
      return this.m.config.default || {};
    },
    model: {
      get() {
        let conf = JSON.parse(JSON.stringify(this.m?.config || {}));
        const f = conf?.watch;
        let config;
        if (f) {
          config = conf?.[this.data[f]];
        }
        config = config || conf.default;
        // this.configHead = config;
        let model = this.calcModelConfig(config);
        return model;
      },
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    makeActionStatus() {
      let act = {};
      this.actions.forEach((a) => {
        if (!this.actionStatus.hasOwnProperty(a.name)) this.$set(this.actionStatus, a.name, 0);
        this.actionStatus[a.name] = this.getActionStatus(a.name);
      });
      // this.actionStatus = act;
    },
    getActionStatus(n) {
      console.log("getActionStatus", n);
      if (n == "orderGoods") {
        let res = -999;
        let data = this.data.data_goods;
        if (!data) return 0;
        for (let i = 0; i < data.length; i++) {
          const el = data[i];
          if (el.result !== "") {
            return -1;
          }
        }
        res = 1;

        return res;
      }
      if (n == "orderService") {
        let res = -999;
        let data = this.data.data_service;
        if (!data) return 0;
        for (let i = 0; i < data.length; i++) {
          const el = data[i];
          if (el.result !== "") return -1;
        }
        res = 1;
        return res;
      }
      if (n == "store") {
        let res = -999;
        let data = this.data.data_store;
        if (!data) return 0;
        for (let i = 0; i < data.length; i++) {
          const el = data[i];
          if (el.result !== "") return -1;
        }
        res = 1;
        return res;
      }
      return -20;
    },
    async doAction(n) {
      const getBalance = async (id, params) => {
        let debit = 0;
        params["doc_id"] = id;
        let resp = await this.$axios.post("/report_sql", { name: "getBalanceByDocument", params });
        this.loading = false;
        if (resp.data.status == "ok") {
          resp.data.data.forEach((el) => {
            debit += el.value;
          });
        }
        return debit;
      };
      if (n == "orderGoods") {
        let resp = await this.$axios.get("/accounting/doc/order_good", {
          params: { filters: { type: 1, object_code: this.data.object_id, status: { condition: "<>", value: 6 } } },
        });
        let data_goods = resp.data.data;
        for (let i = 0; i < data_goods.length; i++) {
          let b = await getBalance(data_goods[i].id, { acc: "60.1", doc_name: "AccountingDocOrderGoodModel" });
          let res = "";
          data_goods[i]["balance"] = b;
          res += data_goods[i].status !== 2 ? "Не проведен" : "";
          if (b !== 0) res += b < 0 ? `Долг ${-b}` : `Переплата`;
          data_goods[i]["result"] = res;
        }
        this.data.data_goods = data_goods;
        let data = { id: this.data.id, data_goods };
        await this.save(this.api, data);
        this.actionStatus[n] = this.getActionStatus(n);
      }
      if (n == "orderService") {
        let resp = await this.$axios.get("/accounting/doc/order_service", {
          params: { filters: { type: 1, object_code: this.data.object_id, status: { condition: "<>", value: 6 } } },
        });
        let data_service = resp.data.data;

        for (let i = 0; i < data_service.length; i++) {
          let b = await getBalance(data_service[i].id, { acc: "60.2", doc_name: "AccountingDocOrderServiceModel" });
          let res = "";
          data_service[i]["balance"] = b;
          res += data_service[i].status !== 2 ? "Не проведен" : "";
          if (b !== 0) res += b < 0 ? `Долг ${-b}` : `Переплата`;
          data_service[i]["result"] = res;
        }

        let data = { id: this.data.id, data_service };
        await this.save(this.api, data);
        this.data.data_service = data_service;
        this.actionStatus[n] = this.getActionStatus(n);
      }
      if (n == "store") {
        let resp = await this.$axios.post("/report_sql", { name: "objectClose_storeCheck", params: { object_id: this.data.object_id } });
        let data_store = resp.data.data;
        for (let i = 0; i < data_store.length; i++) {
          let res = "";
          res += data_store[i].object_id !== this.data.object_id ? "Неверное место хранения" : "";
          data_store[i]["result"] = res;
        }
        this.data.data_store = data_store;
        let data = { id: this.data.id, data_store };
        await this.save(this.api, data);
        this.actionStatus[n] = this.getActionStatus(n);
        //this.getActionStatus(n);
      }
      this.tab = "tab-" + n;
    },
    async validateDocument() {
      if (await this.validateData(this.data, this.model, true)) {
        if (this.getAccess("objectClose.skipCheck")) return true;
        for (let i = 0; i < this.actions.length; i++) {
          await this.doAction(this.actions[i].name);
          let r = this.getActionStatus(this.actions[i].name);
          if (r !== 1) {
            this.$root.$emit("show-info", {
              type: "error",
              text: `Не выполнено условие (${this.actions[i].title})`,
            });
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    async beforeChangeStatus(status) {
      if ([2].includes(status.value)) {
        if (await this.validateDocument()) {
          return await this.getBalanceTotal();
        } else return false;
      } else if ([3].includes(status.value)) {
        return await this.validateDocument();
      }
      return true;
    },

    getDetailsModel(n) {
      return this.m["list" + n.upFirst()];
    },

    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close", this.data);
      else this.$router.push({ name: "objectClose" });
    },
    async getBalanceTotal() {
      if (!this.data.object_id) return;
      const id = this.data.object_id;
      let resp = await this.$axios.post("/report_sql", { name: "getObjectBalanceTotal", params: { id } });
      if (resp.data.status == "ok") {
        const d = resp.data.data[0];
        this.$set(this.balance, "debit", d.value_db);
        this.$set(this.balance, "credit", d.value_cr);
        return { data: { account_data: { debit: d.value_db, credit: d.value_cr } } };
      }
      return false;
    },
    afterFetchData(r) {
      if (this.id) {
        //   this.calcModel();
        this.makeActionStatus();
        this.getBalanceTotal();
      }
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },

    onClickRow(e, d = {}) {
      if (e.field?.isMenu) {
        let name = e.field?.alias || e.field.name;
        let id = e.row?.[e.field.name];
        if (id) this.showPopupMenu(name, e.row, e.event, { task: { id: e.row[e.field.name] } });
      } else if (e.field.name == "actions") {
      } else {
        if (["rooms", "works", "goods"].includes(d.name)) {
          this.idEdit = e.row.id;
          this.detailIndex = d.detailIndex;
          this.detailName = d.name;
          this.fieldsRO = [];
          this.showDialogEditDetail = true;
          return;
        }
      }
    },
  },
};
</script>
